export class PathConstants {
  // Firestore Paths
  public static getUserDetailsPath(userId: string): string {
    return `/historian-users/${userId}`;
  }

  public static getOrgDetailsPath(orgCode: string): string {
    return `/historian-orgs/historian-org-${orgCode}`;
  }

  // RTDB Paths
  public static getOrdersPath(userId: string): string {
    return `/provider-historians/${userId}/ordered-patients`;
  }

  public static getSelectedOrderPath(userId: string, orderId: string): string {
    return `/provider-historians/${userId}/ordered-patients/${orderId}`;
  }

  public static getPatientPath(orgCode: string, patientKey: string): string {
    return `/provider-org-${orgCode}/patients/${patientKey}`;
  }

  public static getPatientEmploymentPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/employmentInfo`;
  }

  public static getPatientInsurancePath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/healthInsurance`;
  }

  public static getPatientAppointmentsPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patient-appointments/${patientKey}`;
  }
  public static getPatientAttorneyPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/attorney`;
  }

  public static getPatientDefAttorneyPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/defenseAttorney`;
  }

  public static getPatientDocumentsPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/filestore`;
  }

  public static getOrgMetadata(orgCode: string): string {
    return `/provider-org-${orgCode}/0rg-metadata`;
  }

  public static deletePatientDocumentsPath(
    orgCode: string,
    patientKey: string,
    docKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/filestore/${docKey}`;
  }

  public static getPatientNotesPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/history-notes`;
  }

  public static getStoragePath(orgCode: string, patientKey: string): string {
    return `provider-org-${orgCode}/filestore/${patientKey}`;
  }

  public static getFileCollectionPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `provider-org-${orgCode}/patients/${patientKey}/filestore`;
  }

  public static getHistoryFormsPath(orgCode: string): string {
    return `/provider-org-${orgCode}/history-forms`;
  }

  public static getOrgRootpath(orgCode: string): string {
    return `provider-org-${orgCode}`;
  }

  public static getReportsPath(orgCode: string): string {
    return `/provider-org-${orgCode}/reports`;
  }

  public static getPatientReportsPath(
    orgCode: string,
    patientKey: string,
    reportKey: string
  ): string {
    return `/provider-org-${orgCode}/patients/${patientKey}/reports/${reportKey}`;
  }

  public static getHistoryFormPath(
    orgCode: string,
    patientKey: string,
    formName: string
  ) {
    return `provider-org-${orgCode}/patients/${patientKey}/forms/${formName}`;
  }

  public static getLowBackDisabilityPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `provider-org-${orgCode}/patients/${patientKey}/workability/lowBackDisability`;
  }

  public static getNeckDisabilityPath(
    orgCode: string,
    patientKey: string
  ): string {
    return `provider-org-${orgCode}/patients/${patientKey}/workability/neckDisability`;
  }

  public static getOrderStatusUpdatePath(
    orgCode: string,
    patientKey: string
  ): string {
    return `provider-org-${orgCode}/patients/${patientKey}/historyOrder`;
  }

  public static getProviderOrgDataPath(orgCode: string): string {
    return `provider-org-${orgCode}/0rg-metadata`;
  }
}
