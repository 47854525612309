import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { RibbonComponent } from '@mlc/shared//ribbon/ribbon.component';
import { ToastMessageComponent } from '@mlc/shared/toast-message/toast-message-component.component';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RibbonComponent, ToastMessageComponent],
  template: `
    <router-outlet></router-outlet>
    <app-ribbon [environment]="environment"></app-ribbon>
    <app-toast-message-component
      class="toast-message"
    ></app-toast-message-component>
  `,
})
export class AppComponent implements OnInit {
  environment = environment;
  private matIconReg = inject(MatIconRegistry);
  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }
}
