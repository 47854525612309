import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  sendPasswordResetEmail,
  User,
  user,
} from '@angular/fire/auth';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { ActivateSuspendDialogComponent } from '../components/activate-suspend-dialog/activate-suspend-dialog.component';
import { PathConstants } from '../db-path';
import { Historian, Organization } from '../model/shared-model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private afAuth = inject(Auth);
  private authState$ = authState(this.afAuth);
  private firestore = inject(Firestore);
  // TODO: Remove dialog handling from the service, instead move this to a component
  private dialog = inject(MatDialog);

  constructor() {
    // this.afAuth.onAuthStateChanged((user) => {
    //   onAuthStateChanged(this.afAuth, (user) => {
    //     if (!user) {
    //       this.userSubject.next(null);
    //       return;
    //     }
    //     const path = PathConstants.getUserDetailsPath(user.uid);
    //     const userDocRef = doc(this.firestore, path);
    //     docData(userDocRef)
    //       .subscribe((userData: any) => {
    //         if (userData && userData.orgCode && userData.approved) {
    //           this.userSubject.next(userData);
    //         } else {
    //           this.logout();
    //         }
    //       });
    //   });
    // }
    this.authState$
      .pipe(
        switchMap((user: User | null) => {
          console.log('[AuthService] authState - user:', user);
          if (!user) {
            return of(null);
          }
          const path = PathConstants.getUserDetailsPath(user.uid);
          // console.log('[AuthService] authState - path:', path);
          const docRef = doc(this.firestore, path);
          return docData<any>(docRef, { idField: 'id' }).pipe(
            map((userData) => ({ ...userData, metadata: user.metadata }))
          );
        })
      )
      .subscribe((user) => {
        // this.orgCode = user?.orgCode;
        this.userSubject.next(user);
      });
  }

  updateUserIfo(user: any, uid: any) {
    console.log(user, 'updates user info');
    const path = PathConstants.getUserDetailsPath(uid);
    const userDocRef = doc(this.firestore, path);
    return updateDoc(userDocRef, user).then(() => this.userSubject.next(user));
  }

  getLoggedInUser(): Observable<Historian | any> {
    return this.userSubject.asObservable();
  }

  isValidLogin(): Observable<boolean> {
    return user(this.afAuth).pipe(
      switchMap((user) => user ? docData(doc(this.firestore, PathConstants.getUserDetailsPath(user.uid)), { idField: 'id' }) : of(null)),
      map((userData) => !!userData && userData['approved'] === true),
    );
  }
  

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  getOrgDetails(): Observable<Organization | undefined> {
    return this.getLoggedInUser().pipe(
      switchMap((user) => {
        if (!user || !user.orgCode) return of(null);
        const path = PathConstants.getOrgDetailsPath(user.orgCode);
        const userDocRef = doc(this.firestore, path);
        return docData<any>(userDocRef);
      })
    );
  }

  getOrgUsers(): Observable<any> {
    return this.getLoggedInUser().pipe(
      switchMap((user: any) => {
        if (!user) {
          return of([]);
        } else {
          const usersQuery = query(
            collection(this.firestore, 'historian-users'),
            where('orgCode', '==', user.orgCode)
          );
          return collectionData(usersQuery);
        }
      })
    );
  }

  // TODO: Remove dialog handling from the service, instead move this to a component
  userActivation(user: Historian): Observable<Historian | undefined> {
    const action = user.approved ? 'suspend' : 'activate';
    const dialogRef = this.dialog.open(ActivateSuspendDialogComponent, {
      width: '20vw',
      data: { action },
    });

    return dialogRef.afterClosed().pipe(
      switchMap((result) => {
        if (result === 'confirm') {
          user.approved = !user.approved;
          if (!user.approved) {
            user.suspendedDate = new Date().toISOString();
          }
          const path = PathConstants.getUserDetailsPath(user.uid);
          const userDocRef = doc(this.firestore, path);
          const updateData = {
            approved: user.approved,
          };
          return updateDoc(userDocRef, updateData).then(() => user);
        } else {
          return of(user);
        }
      })
    );
  }

  logout() {
    return this.afAuth.signOut().then(() => {
      this.userSubject.next(null);
    });
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }
}
